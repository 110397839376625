<template>
<v-card flat :height="$vuetify.breakpoint.mobile ? '' : '527px'" :class="
  [
    $vuetify.breakpoint.mobile ? '' : `card-bg-step-${step}`,
    withoutDescription ? 'no-pointer' : ''
  ]"
  v-show="bgLoaded"
  v-intersect="{
    handler: onIntersect,
    options: {
      threshold: [0, 0.5, 1.0]
    }
  }">
  <v-container :fluid="$vuetify.breakpoint.mobile" fill-height>
    <v-row>

      <!-- Incorporación de prop withLogo... TODO: NO ES AUTOEXCLUYENTE CON LOS COMPONENTES ANTERIORES -->
      <v-col cols="12" sm="12" md="12" lg="6" 
        v-if="withLogo" 
        align-self="center">
          <img eager
            :src="$vuetify.breakpoint.mobile ? 'assets/tokenfit-logo-full.svg' :'assets/tokenfit-logo-full.png'"
            :class="['auto-margin', $vuetify.breakpoint.mobile ? 'logo-mobile' : 'logo-desktop']" />
      </v-col>

      <!-- sección izquierda según si step es par o impar -->
      <v-col 
        v-if="(isOdd || $vuetify.breakpoint.mobile) && title.length > 0 && notZeroStep" 
        cols="12" sm="12" md="12" lg="4" 
        :class="
        [
          $vuetify.breakpoint.mobile ? `text-center pt-12 card-bg-step-${step}-mobile-top` : 'text-right'
        ]"
        align-self="center">
        <v-slide-x-transition mode="out-in">
          <h1 :class="
            [
              `title-color-step-${step} display-2`,
              $vuetify.breakpoint.mobile ? 'pb-5 pt-3' : ''
            ]"
            v-show="intersecting">{{ title }}</h1>
        </v-slide-x-transition>
        <v-slide-x-transition mode="out-in">
          <h2 v-show="intersecting" v-if="detalle.length <= 0 && subTitle">{{ subTitle }}</h2>
          <detalle-base-section class="pb-10" v-if="detalle.length > 0" v-show="intersecting" :detalle="detalle" />
        </v-slide-x-transition>
      </v-col>
      <v-col 
        v-if="!(isOdd || $vuetify.breakpoint.mobile) && content.length > 0 && notZeroStep" 
        cols="6" class="text-right" align-self="center">
        <v-slide-x-transition mode="out-in">
          <p class="chivo" v-html="content" v-show="intersecting"></p>
        </v-slide-x-transition>
        <v-fade-transition>
          <saber-mas-button v-if="secondButton && secondButton == 'SABER MÁS'" :title="secondButton" no-block class="mr-3"  v-show="intersecting" />
          <news-button v-if="secondButton && secondButton.indexOf('NEWS') >= 0" :title="secondButton" no-block class="mr-3"  v-show="intersecting" />
          <inscribite-button v-if="secondButton && secondButton != 'SABER MÁS' && secondButton.indexOf('NEWS') < 0" :title="secondButton" no-block class="mr-3"  v-show="intersecting" />
        </v-fade-transition>
        <v-fade-transition>
          <accordion-detalles-button 
            :content="content" 
            :without-description="withoutDescription" 
            :intersecting="intersecting"/>
        </v-fade-transition>
      </v-col>


 
      <!-------------------------------------------------------->

      <v-col cols="12" sm="12" md="12" lg="2" 
        :class="
        [
          'pt-0',
          'pb-0',
          $vuetify.breakpoint.mobile ? 'pl-0 pr-0' : (isOdd ? 'pl-9' : 'pl-0'),
          withoutPolygon ? ($vuetify.breakpoint.mobile ? '' : 'text-left') : '',
          $vuetify.breakpoint.mobile ? `card-bg-step-${step}-mobile-top` : ''
        ]" >
        <polygon-component 
          v-if="!withoutPolygon" 
          ref="polygonComponent" 
          :step="step" 
          :filledPercent="filledPercent" 
          :isOdd="isOdd"
          :polygon-with-icon="polygonWithIcon"
          :step-decorator="stepDecorator"
          @load="bgLoaded = true" />
        <img @load="bgLoaded = true" v-if="withoutPolygon && $vuetify.breakpoint.mobile" :src="`assets/tokenfit-bg-step-${step}-mobile.png`" style="height:102% ; width: 100%"/> <!-- se usa 102% para que cubra todo el bottom -->
        <img @load="bgLoaded = true" v-if="withoutPolygon && !$vuetify.breakpoint.mobile" :src="`assets/tokenfit-bg-step-${step}.png`" style="margin-left: auto;"/>
      </v-col>

      <!-- sección derecha según si step es par o impar -->
      <v-col 
        v-if="(isOdd || $vuetify.breakpoint.mobile) && content.length > 0" 
        cols="12" sm="12" md="12" lg="6" 
        :class="
          [
            'pl-7 on-top',
            $vuetify.breakpoint.mobile ? `pt-5 pb-5 card-bg-step-${step}-mobile-bottom text-center` : 'text-left '
          ]" 
        align-self="center">
        <v-slide-x-reverse-transition mode="out-in">
          <p class="chivo text-left" v-html="content" v-show="intersecting"></p>
        </v-slide-x-reverse-transition>

        <!-- this is shown only in mobile view -->
        <template v-if="$vuetify.breakpoint.mobile">
          <v-fade-transition>
            <saber-mas-button 
              v-if="secondButton && secondButton == 'SABER MÁS'" 
              :title="secondButton" 
              no-block 
              class="mt-12" 
              v-show="intersecting" />
            <news-button
              class="d-block auto-margin mt-12"
              v-if="secondButton && secondButton.indexOf('NEWS') >= 0" 
              :title="secondButton" 
              no-block
              v-show="intersecting" />                
            <inscribite-button
              class="d-block auto-margin mt-12"
              v-if="secondButton && secondButton != 'SABER MÁS' && secondButton.indexOf('NEWS') < 0" 
              :title="secondButton" 
              no-block
              v-show="intersecting" />            
          </v-fade-transition>
          <v-fade-transition>
          <accordion-detalles-button 
            :content="content" 
            :without-description="withoutDescription" 
            :intersecting="intersecting"/>
          </v-fade-transition>
        </template>
        <!----------------------------------------------->

        <!-- this is shown only in desktop view -->
        <template v-else>
          <v-fade-transition>
            <accordion-detalles-button 
              :content="content" 
              :without-description="withoutDescription" 
              :intersecting="intersecting"/>
          </v-fade-transition>
          <v-fade-transition>
            <saber-mas-button 
              v-if="secondButton && secondButton == 'SABER MÁS'" 
              :title="secondButton" 
              no-block :class="!withoutDescription ? 'ml-3' : ''" 
              v-show="intersecting" />
            <news-button 
              v-if="secondButton && secondButton.indexOf('NEWS') >= 0" 
              :title="secondButton" 
              no-block :class="!withoutDescription ? 'ml-3' : ''" 
              v-show="intersecting" />                            
            <inscribite-button
              v-show="intersecting"
              v-if="secondButton && secondButton != 'SABER MÁS' && secondButton.indexOf('NEWS') < 0" 
              :title="secondButton" 
              no-block 
              :class="!withoutDescription ? 'ml-3' : ''" />
          </v-fade-transition>
        </template>
      </v-col>
      <v-col 
        v-if="(!isOdd && !$vuetify.breakpoint.mobile) || !notZeroStep" 
        cols="12" lg="4" 
        :class="
        [
          $vuetify.breakpoint.mobile ? `text-center pa-10 card-bg-step-${step}-mobile-bottom` :'text-left',
          'line-spacing'
        ]" 
        align-self="center">
        <v-slide-x-reverse-transition mode="out-in">
          <h1 :class="[
            `title-color-step-${step}`,
            step != '00' ? 'text-xs display-2' : ''
            ]" v-show="intersecting">{{ title }}</h1>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition mode="out-in">
          <h3 class="pt-10 chivo font-weight-regular" v-if="detalle.length <= 0 && subTitle" v-show="intersecting">{{ subTitle }}</h3>
          <detalle-base-section class="pb-10" v-if="detalle.length > 0" :left="false" v-show="intersecting" :detalle="detalle" />
        </v-slide-x-reverse-transition>
      </v-col>      

    </v-row>
  </v-container>
</v-card>
</template>

<script>
export default {
  name: 'BaseSection',
  props: {
    step: {
      type: String,
      default: "00",
    },
    filledPercent: {
      type: String,
      default: '0',
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      defailt: ''
    },
    content: {
      type: String,
      default: ''
    },
    isOpened: {
      type: Boolean,
      default: false
    },
    withoutPolygon: {
      type: Boolean,
      default: false
    },
    withLogo: {
      type: Boolean,
      default: false
    },
    withoutDescription: {
      type: Boolean,
      default: false
    },
    secondButton: {
      type: String,
      default: undefined
    },
    polygonWithIcon: {
      type: String,
      default: undefined
    },
    stepDecorator: {
      type: String,
      default: undefined
    },
    detalle: {
      type: Array,
      default: () => []
    }
  },
  components: {
    PolygonComponent: () => import('@/views/components/Polygon'),
    InscribiteButton: () => import('@/views/components/InscribiteButton'),
    SaberMasButton: () => import('@/views/components/SaberMasButton'),
    NewsButton: () => import('@/views/components/NewsButton'),
    DetalleBaseSection: () => import('@/views/components/DetalleBaseSection'),
    AccordionDetallesButton: () => import('@/views/components/AccordionDetallesButton'),
  },
  data() {
    return {
      bgLoaded: false,
      intersecting: false
    }
  },
  computed: {
    isOdd() {
      return parseInt(this.step) % 2 == 1;
    },
    notZeroStep() {
      return this.step !== '0' && this.step !== '00'
    }
  },
  methods: {
    onIntersect(entries) {
      if (this.$vuetify.breakpoint.mobile) {
        this.intersecting = true
      } else {
        this.intersecting = entries[0].intersectionRatio >= 0.5
      }
    },
    log(data) {
      console.log(data)
    }
  }
}
</script>

<style>
.on-top {
  z-index: 11;
}
.line-spacing {
  line-height: 2em;
}
.logo-mobile {
  width: 67vw;
  margin: 3em auto 3em auto;
}
.logo-desktop {
  max-width: 415px;
}
.no-pointer {
  cursor: initial !important;
}
.action-button {
  width: auto !important;
  display: inline-block !important;
}

</style>